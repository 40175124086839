import { gql } from 'apollo-angular';

import { ProductOverview } from '@ui/shared/models';

export interface LandlordProductsQueryResponse {
  getLandlordProduct: ProductOverview;
}
export interface ProductsQueryResponse {
  getProducts: ProductOverview[];
}
export const priceField = `
    weeklyPriceNet
    monthlyPriceNet
    yearlyPriceNet
    oneTimePaymentNet
    postDiscountWeeklyPriceNet
    postDiscountMonthlyPriceNet
    postDiscountYearlyPriceNet
    weeklyPriceGross
    monthlyPriceGross
    yearlyPriceGross
    oneTimePaymentGross
    postDiscountWeeklyPriceGross
    postDiscountMonthlyPriceGross
    postDiscountYearlyPriceGross
    setup
    vat
`;
export const addonField = `
  id
  amounts {
    renew
    expire
  }
  discount {
    value
    endDate
  }
  type
  price {
    ${priceField}
  }
  nextPrice {
    ${priceField}
  }
  name
  description
  renew
  booked
`;

export const producerOverviewField = `
  product {
    id
    subscriptionPeriod
  }
  trial
  dueDate
  nextProduct {
    id
    subscriptionPeriod
  }
  discount {
    value
    endDate
  }
  addons {
    ${addonField}
  }
`;

export const getLandlordProductQuery = gql`
  query getLandlordProduct($customerID: String) {
    getLandlordProduct(customerID: $customerID) {
        ${producerOverviewField}
    }
  }
`;

export const getProductsQuery = gql`
  query getProducts($input: GetProductInput){
    getProducts(input: $input) {
      ${producerOverviewField}
    }
  }
`;

export const recalculateAddonPriceQuery = gql`
  query recalculateAddonPrice($input: AddonPriceCalculation) {
    recalculateAddonPrice(input: $input) {
      price {
        ${priceField}
      }
      nextPrice {
        ${priceField}
      }
    }
  }
`;

export const recalculateAddonsPriceQuery = gql`
  query recalculateAddonsPrice($input: AddonsPriceCalculation) {
    recalculateAddonsPrice(input: $input) {
      ${addonField}
    }
  }
`;

export const updateSubscriptionMutation = gql`
  mutation updateSubscription($payload: UpdateSubscriptionInput) {
    updateSubscription(input: $payload) {
      ${producerOverviewField}
    }
  }
`;

export const createSubscriptionMutation = gql`
  mutation createSubscription($payload: CreateSubscriptionInput) {
    createSubscription(input: $payload) {
      ${producerOverviewField}
    }
  }
`;

export const updateNextProductMutation = gql`
  mutation updateNextProduct($productId: String!, $customerId: String!) {
    updateNextProduct(productId: $productId, customerId: $customerId)
  }
`;
