import { Action } from '@ngrx/store';

import {
  Customer,
  FilterOptions,
  Invoice,
  LandlordUser,
  MailConfigCustomer,
  Pagination
} from '@ui/shared/models';
import { CustomerInput } from '../queries';

export const FETCH_USERS = '[Landlord Page] Fetch Users List';
export const FETCH_USERS_SUCCESS = '[Landlord Page] Fetch Users List Success';
export const FETCH_USERS_FAIL = '[Landlord Page] Fetch Users List Fail';

export class FetchUsers implements Action {
  readonly type = FETCH_USERS;

  constructor(public data: FilterOptions) {}
}

export class FetchUsersSuccess implements Action {
  readonly type = FETCH_USERS_SUCCESS;

  constructor(
    public data: LandlordUser[],
    public pagination: Pagination
  ) {}
}

export class FetchUsersFail implements Action {
  readonly type = FETCH_USERS_FAIL;

  constructor(public error: Error) {}
}

export const FETCH_CUSTOMERS = '[Landlord Page] Fetch Customers List';
export const FETCH_CUSTOMERS_SUCCESS =
  '[Landlord Page] Fetch Customers List Success';
export const FETCH_CUSTOMERS_FAIL = '[Landlord Page] Fetch Customers List Fail';

export class FetchCustomers implements Action {
  readonly type = FETCH_CUSTOMERS;

  constructor(public data: FilterOptions) {}
}

export class FetchCustomersSuccess implements Action {
  readonly type = FETCH_CUSTOMERS_SUCCESS;

  constructor(
    public data: Customer[],
    public pagination: Pagination
  ) {}
}

export class FetchCustomersFail implements Action {
  readonly type = FETCH_CUSTOMERS_FAIL;

  constructor(public error: Error) {}
}

export const FETCH_INVOICES = '[Landlord Page] Fetch Invoices List';
export const FETCH_INVOICES_SUCCESS =
  '[Landlord Page] Fetch Invoices List Success';
export const FETCH_INVOICES_FAIL = '[Landlord Page] Fetch Invoices List Fail';

export class FetchInvoices implements Action {
  readonly type = FETCH_INVOICES;

  constructor(public data: CustomerInput) {}
}

export class FetchInvoicesSuccess implements Action {
  readonly type = FETCH_INVOICES_SUCCESS;

  constructor(
    public data: Invoice[],
    public pagination: Pagination
  ) {}
}

export class FetchInvoicesFail implements Action {
  readonly type = FETCH_INVOICES_FAIL;

  constructor(public error: Error) {}
}

export const CANCEL_INVOICE = '[Landlord Page] Cancel Invoice';
export const CANCEL_INVOICE_SUCCESS = '[Landlord Page] Cancel Invoice Success';
export const CANCEL_INVOICE_FAIL = '[Landlord Page] Cancel Invoice Fail';

export class CancelInvoice implements Action {
  readonly type = CANCEL_INVOICE;

  constructor(public invoiceId: string) {}
}

export class CancelInvoiceSuccess implements Action {
  readonly type = CANCEL_INVOICE_SUCCESS;
}

export class CancelInvoiceFail implements Action {
  readonly type = CANCEL_INVOICE_FAIL;

  constructor(public error: Error) {}
}

export const CHANGE_USER_ENABLED = '[Landlord Page] Change Enable ';
export const CHANGE_USER_ENABLED_SUCCESS =
  '[Landlord Page] Change Enable Success';
export const CHANGE_USER_ENABLED_FAIL = '[Landlord Page] Change Enable Fail';

export class ChangeUserEnabled implements Action {
  readonly type = CHANGE_USER_ENABLED;

  constructor(
    public id: string,
    public enabled: boolean
  ) {}
}

export class ChangeUserEnabledSuccess implements Action {
  readonly type = CHANGE_USER_ENABLED_SUCCESS;

  constructor(public data: LandlordUser) {}
}

export class ChangeUserEnabledFail implements Action {
  readonly type = CHANGE_USER_ENABLED_FAIL;

  constructor(public error: Error) {}
}

export const FETCH_CUSTOMER_BY_ID = '[Landlord Page] Fetch Customer By Id';
export const FETCH_CUSTOMER_BY_ID_SUCCESS =
  '[Landlord Page] Fetch Customer By Id Success';
export const FETCH_CUSTOMER_BY_ID_FAIL =
  '[Landlord Page] Fetch Customer By Id Fail';

export class FetchCustomerById implements Action {
  readonly type = FETCH_CUSTOMER_BY_ID;

  constructor(public id: string) {}
}

export class FetchCustomerByIdSuccess implements Action {
  readonly type = FETCH_CUSTOMER_BY_ID_SUCCESS;

  constructor(public data: Customer[]) {}
}

export class FetchCustomerByIdFail implements Action {
  readonly type = FETCH_CUSTOMER_BY_ID_FAIL;

  constructor(public error: Error) {}
}

export const FETCH_INVOICE_BY_ID = '[Landlord Page] Fetch Invoice By Id';
export const FETCH_INVOICE_BY_ID_SUCCESS =
  '[Landlord Page] Fetch Invoice By Id Success';
export const FETCH_INVOICE_BY_ID_FAIL =
  '[Landlord Page] Fetch Invoice By Id Fail';

export class FetchInvoiceById implements Action {
  readonly type = FETCH_INVOICE_BY_ID;

  constructor(public invoiceId: string) {}
}

export class FetchInvoiceByIdSuccess implements Action {
  readonly type = FETCH_INVOICE_BY_ID_SUCCESS;

  constructor(public data: Invoice[]) {}
}

export class FetchInvoiceByIdFail implements Action {
  readonly type = FETCH_INVOICE_BY_ID_FAIL;

  constructor(public error: Error) {}
}

export const IMPERSONATE_USER = '[Landlord Page] Impersonate User';
export const IMPERSONATE_USER_SUCCESS =
  '[Landlord Page] Impersonate User Success';
export const IMPERSONATE_USER_FAIL = '[Landlord Page] Impersonate User Fail';

export class ImpersonateUser implements Action {
  readonly type = IMPERSONATE_USER;

  constructor(public email: string) {}
}

export class ImpersonateUserSuccess implements Action {
  readonly type = IMPERSONATE_USER_SUCCESS;
}

export class ImpersonateUserFail implements Action {
  readonly type = IMPERSONATE_USER_FAIL;

  constructor(public error: Error) {}
}

export const DELETE_CUSTOMER = '[Landlord Page] Delete Customer';
export const DELETE_CUSTOMER_SUCCESS =
  '[Landlord Page] Delete Customer Success';
export const DELETE_CUSTOMER_FAIL = '[Landlord Page] Delete Customer Fail';

export class DeleteCustomers implements Action {
  readonly type = DELETE_CUSTOMER;

  constructor(public id: string) {}
}

export class DeleteCustomersSuccess implements Action {
  readonly type = DELETE_CUSTOMER_SUCCESS;
}

export class DeleteCustomersFail implements Action {
  readonly type = DELETE_CUSTOMER_FAIL;

  constructor(public error: Error) {}
}

export const CHANGE_MAIL_CONFIG_CUSTOMER =
  '[Landlord Page] Change Mail Config Customer';
export const CHANGE_MAIL_CONFIG_CUSTOMER_SUCCESS =
  '[Landlord Page] Change Mail Config Customer Success';
export const CHANGE_MAIL_CONFIG_CUSTOMER_FAIL =
  '[Landlord Page] Change Mail Config Customer Fail';

export class ChangeMailConfigCustomer implements Action {
  readonly type = CHANGE_MAIL_CONFIG_CUSTOMER;

  constructor(
    public id: string,
    public mailConfigCustomer: MailConfigCustomer
  ) {}
}

export class ChangeMailConfigCustomerSuccess implements Action {
  readonly type = CHANGE_MAIL_CONFIG_CUSTOMER_SUCCESS;
}

export class ChangeMailConfigCustomerFail implements Action {
  readonly type = CHANGE_MAIL_CONFIG_CUSTOMER_FAIL;

  constructor(public error: Error) {}
}

export const FETCH_MAIL_CONFIG_CUSTOMER =
  '[Landlord Page] Fetch Mail Config Customer';
export const FETCH_MAIL_CONFIG_CUSTOMER_SUCCESS =
  '[Landlord Page] Fetch Mail Config Customer Success';
export const FETCH_MAIL_CONFIG_CUSTOMER_FAIL =
  '[Landlord Page] Fetch Mail Config Customer Fail';

export class FetchMailConfigCustomer implements Action {
  readonly type = FETCH_MAIL_CONFIG_CUSTOMER;

  constructor(public id: string) {}
}

export class FetchMailConfigCustomerSuccess implements Action {
  readonly type = FETCH_MAIL_CONFIG_CUSTOMER_SUCCESS;

  constructor(public mailConfigCustomer: MailConfigCustomer) {}
}

export class FetchMailConfigCustomerFail implements Action {
  readonly type = FETCH_MAIL_CONFIG_CUSTOMER_FAIL;

  constructor(public error: Error) {}
}

export const ENABLE_EXTERNAL_PRICING =
  '[Landlord Page] Enable External Pricing';

export const ENABLE_EXTERNAL_PRICING_SUCCESS =
  '[Landlord Page] Enable External Pricing Success';

export class EnableExternalPricing implements Action {
  readonly type = ENABLE_EXTERNAL_PRICING;

  constructor(
    public id: string,
    public enable: boolean
  ) {}
}

export class EnableExternalPricingSuccess implements Action {
  readonly type = ENABLE_EXTERNAL_PRICING_SUCCESS;

  constructor(
    public id: string,
    public enable: boolean
  ) {}
}

export type LandlordAction =
  | FetchUsers
  | FetchUsersSuccess
  | FetchUsersFail
  | FetchCustomers
  | FetchCustomersSuccess
  | FetchCustomersFail
  | FetchInvoices
  | FetchInvoicesSuccess
  | FetchInvoicesFail
  | ChangeUserEnabled
  | ChangeUserEnabledSuccess
  | ChangeUserEnabledFail
  | FetchCustomerById
  | FetchCustomerByIdSuccess
  | FetchCustomerByIdFail
  | FetchInvoiceById
  | FetchInvoiceByIdSuccess
  | FetchInvoiceByIdFail
  | ImpersonateUser
  | ImpersonateUserSuccess
  | ImpersonateUserFail
  | CancelInvoice
  | CancelInvoiceSuccess
  | CancelInvoiceFail
  | DeleteCustomers
  | DeleteCustomersFail
  | DeleteCustomersSuccess
  | ChangeMailConfigCustomer
  | ChangeMailConfigCustomerFail
  | ChangeMailConfigCustomerSuccess
  | FetchMailConfigCustomer
  | FetchMailConfigCustomerFail
  | FetchMailConfigCustomerSuccess
  | EnableExternalPricing
  | EnableExternalPricingSuccess;
